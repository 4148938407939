import React from 'react'
import { Col, Row} from "react-bootstrap"

import {GiInfinity} from "react-icons/gi"

const Erfahrung = () => {
    return (

            <Row className="text-center">

                <Col sm>
        <p className="h2">1</p> <br />
        <b>Vision</b>
        </Col>

      <Col sm>
        <p className="h2"><GiInfinity /></p> <br />
      <b>Ideen</b>
      </Col>

      <Col sm>
        <p className="h2">+20</p><br />
        <b>Jahre Erfahrung</b>
      </Col>
      </Row>

    )
}

export default Erfahrung
