import React from 'react'

const designs = () => {
    return (
        <div className="text-center">
        <h2 className="display-4">Digitale Designs. </h2>

<p className="lead">Zuverlässig, funktionell, formschön.</p>
</div>
    )
}

export default designs
