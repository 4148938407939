import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Leistungen from "../components/startseite/leistungen"
import Designs from "../components/startseite/designs"
import Erfahrung from "../components/startseite/erfahrung"
import Kontakt from "../components/kontakt"

const IndexPage = () => (
  <Layout>
    <SEO title="Startseite" />
    <Image />
    <div className="jumbotron">
      <Leistungen />
    </div>
    <div
      style={{
        fontSize: `0.7rem`,
        textAlign: `center`,
        letterSpacing: `2px`,
      }}
    >
      <AniLink
        cover
        to="/panorama/panorama-galerie"
        direction="up"
        bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
      >
        Panorama-Beispiel
      </AniLink>
    </div>
    <hr />
    <Designs />
    <hr />
    <Erfahrung />
    <hr />
    <Kontakt />
  </Layout>
)

export default IndexPage
