import React from "react"
import Styles from "./kontakt.module.css"

const kontakt = () => {
  return (
    <div className="text-center">
      <h2 className="display-4">Kontakt</h2>

      <form
        className={Styles.form}
        name="Kontakt"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <p className={Styles.vip}>
          <label htmlFor="required">
            Nachricht um nicht zu senden:
            <input name="bot-field" id="required" />
          </label>
        </p>

        <p>
          <input
            type="text"
            name="Name"
            id="Name"
            placeholder="Ihr Name"
            className={Styles.formControl}
            required
          />
        </p>

        <p>
          <input
            type="text"
            name="Organisation"
            id="Organisation"
            placeholder="Organisation (Optional)"
            className={Styles.formControl}
          />
        </p>

        <p>
          <input
            type="text"
            name="Telefon"
            id="Telefon"
            placeholder="Telefon (Optional)"
            className={Styles.formControl}
          />
        </p>

        <p>
          <input
            type="email"
            name="email"
            id="email"
            className={Styles.formControl}
            placeholder="Email-Adresse"
            required
          />
        </p>

        <p>
          <textarea
            name="Nachricht"
            id="Nachricht"
            placeholder="Nachricht"
            className={Styles.formControl}
            required
          ></textarea>
        </p>
        <p>
          <button type="submit" className={`btn ${Styles.submit}`}>
            Nachricht Senden
          </button>
        </p>
      </form>
    </div>
  )
}

export default kontakt
