import React from 'react'

import {FaHandshake, FaChartLine} from "react-icons/fa"
import {GiFruitTree} from "react-icons/gi"

import {Container, Col, Row} from "react-bootstrap"
const leistungen = () => {
    return (
        <Container>
        <Row className="text-center">
          <Col sm><FaHandshake className="display-1 inkblue-light" /><h2>Beratung.</h2>
            <p>Wir beraten & bieten Fortbildungen für Unternehmen, Startups und Mittelständische Betriebe seit 2008.</p>
              
            <p>Welche Investitionen lohnen sich - und welche nicht? Wir beraten gerne.</p>
    
            <p><b>Fair, offen und verständlich.</b></p>
          
          </Col>
  
          <Col sm><FaChartLine className="display-1 inkblue-light" /><h2>Marketing.</h2>
  
           <p>Entwicklung von Marketing-Kampagnen und -Konzepten.</p>
  
           <p>Individuell. Auf den richtigen Plattformen. </p>
  
           <p><b>Mit Webdesign, Flyergestaltung, Fotoaufnahmen und Videokampagnen.</b></p>
           </Col>
  
          <Col sm><GiFruitTree className="display-1 inkblue-light" /><h2>Strategien.</h2>
          <p>Erfolgreiches Handeln erfordert Planung. Welcher Schritt ist der nächste?</p>
          <p>Chancen erkennen und Ziele erreichen - mit uns.</p>
          <p><b>Wir liefern Strategien für Wachstum.</b></p>
          </Col>
        </Row>
      </Container>
    )
}

export default leistungen
